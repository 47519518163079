<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Benefits",
  metaInfo: {
    title: "Benefits",
    titleTemplate: "%s | Tentifly - Smart sales | booking assistant"
  },
  extends: View,

  mixins: [LoadSections([
    //"hero-alt"
    , "features"
    ])],

  props: {
    id: {
      type: String,
      default: "home"
    }
  }
};
</script>
